<template>
  <div class="page">
    <Navbar title="个人爱好" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-field
        label-width="120px"
        v-model="hobby.food"
        label="喜欢的一种食物"
        placeholder="请输入喜欢的一种食物"
        :rules="[{ required: true, message: '请输入喜欢的一种食物' }]"
      />
      <van-field
        label-width="120px"
        v-model="hobby.book"
        label="喜欢的一本书籍"
        placeholder="请输入喜欢的一本书籍"
        :rules="[{ required: true, message: '请输入喜欢的一本书籍' }]"
      />
      <van-field
        label-width="120px"
        v-model="hobby.sport"
        label="喜欢的一种运动"
        placeholder="请输入喜欢的一种运动"
        :rules="[{ required: true, message: '请输入喜欢的一种运动' }]"
      />
      <van-field
        label-width="120px"
        v-model="hobby.movie"
        label="喜欢的一部电影"
        placeholder="请输入喜欢的一部电影"
        :rules="[{ required: true, message: '请输入喜欢的一部电影' }]"
      />
      <van-field
        label-width="120px"
        v-model="hobby.song"
        label="喜欢的一首歌曲"
        placeholder="请输入喜欢的一首歌曲"
        :rules="[{ required: true, message: '请输入喜欢的一首歌曲' }]"
      /><van-field
        label-width="120px"
        v-model="hobby.celebrity"
        label="喜欢的一个名人"
        placeholder="请输入喜欢的一个名人"
        :rules="[{ required: true, message: '请输入喜欢的一个名人' }]"
      />
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            :color="COLOR"
            size="small"
            icon="passed"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Form, Field, CellGroup } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup
  },
  data () {
    return {
      step: 2,
      personCode: '',
      loadingShow: false,
      hobby: { food: '', book: '', sport: '', movie: '', song: '', celebrity: '' }
    }
  },
  mounted () {
    var query = this.$route.query
    var code = query.personCode
    if (code !== '' && code !== undefined && code !== null) {
      this.personCode = code
      this.retrieveAttr()
    }
    this.$refs.share.default('main')
  },
  methods: {
    async submit () {
      this.loadingShow = true
      var attrs = [
        { key: 'PSN_HBY_FOOD', type: 'DTL', content: { name: this.hobby.food }, seq: '1' },
        { key: 'PSN_HBY_BOOK', type: 'DTL', content: { name: this.hobby.book }, seq: '1' },
        { key: 'PSN_HBY_SPORT', type: 'DTL', content: { name: this.hobby.sport }, seq: '1' },
        { key: 'PSN_HBY_MOVIE', type: 'DTL', content: { name: this.hobby.movie }, seq: '1' },
        { key: 'PSN_HBY_SONG', type: 'DTL', content: { name: this.hobby.song }, seq: '1' },
        { key: 'PSN_HBY_CELEBRITY', type: 'DTL', content: { name: this.hobby.celebrity }, seq: '1' }
      ]
      var pd = {
        personCode: this.personCode,
        attrs: JSON.stringify(attrs)
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '收入资产',
          message: '保存成功'
        }).then(() => {
          this.$router.go(-1)
        })
      } else {
        this.$dialog.alert({
          title: '收入资产',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async retrieveAttr () {
      var pd = { personCode: this.personCode, group: 'HBY', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_HBY_FOOD') {
            this.hobby.food = obj.name
          } else if (element.key === 'PSN_HBY_BOOK') {
            this.hobby.book = obj.name
          } else if (element.key === 'PSN_HBY_SPORT') {
            this.hobby.sport = obj.name
          } else if (element.key === 'PSN_HBY_MOVIE') {
            this.hobby.movie = obj.name
          } else if (element.key === 'PSN_HBY_SONG') {
            this.hobby.song = obj.name
          } else if (element.key === 'PSN_HBY_CELEBRITY') {
            this.hobby.celebrity = obj.name
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0px 5px;
  margin-bottom: 30px;
}
.title {
  font-size: 13px;
  padding: 8px 0px 0px 10px;
  font-weight: 600;
  text-align: left;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
